<template>
  <div class="huobi">
    <van-nav-bar title="激活" />
    <div class="layout" v-for="item in list" :key="item.id">
      <div class="padded-20 text-center">
        <div class="font-weight">{{item.name}}</div>
        <div class="padded-b-15">
          <van-row>
            <van-col span="8">
              <div class="padded-t-10">购买金额</div>
              <div class="padded-t-10 text-danger font-weight">{{item.price}}元</div>
            </van-col>
            <van-col span="8">
              <div class="padded-t-10">获得股权</div>
              <div class="padded-t-10 text-danger font-weight">{{item.guquan}}股</div>
            </van-col>
            <van-col span="8">
              <div class="padded-t-10">月分红</div>
              <div class="padded-t-10 text-danger font-weight">{{item.fenhong}}元</div>
            </van-col>
          </van-row>
        </div>
        <div class="btn">
          <van-button type="danger" block @click="buy(item)">立即激活</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHuobiList } from '@/utils/api.js'
export default {
  name: 'currency',
  data () {
    return {
      list: []
    }
  },
  created () {
    this.getHuobiList()
  },
  methods: {
    getHuobiList() {
      getHuobiList().then(res => {
        this.list = res.data
      })
    },
    buy(item) {
      this.$router.push({
        name: 'buy',
        query: {
          id: item.id,
          price: item.price
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .huobi {
    background: url(~@/assets/images/bg.png) center no-repeat;
    background-size: 100% 100%;
    height: 94vh;
    .layout {
      background: hsla(0,0%,100%,.7);
      margin: 15px;
      .title {
        color: #2a2a2a;
        font-size: 16px;
        font-weight: 700;
      }
      .btn .van-button--warning{
        background: #f60;
        border-radius: 8px;
        height: 40px;
      }
    }
  }
</style>
